<template>
  <div class="customer">
    <div class="page-header">
      <h1>{{ $t('Customer Management') }} > {{ $t('Customer Information') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Customer Information') }}</h2>
      </div>
      <CustomerForm @action="updateCustomer"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Customer from '@/lib/customer';
import CustomerForm from '@/components/customer/CustomerForm.vue';

export default {
  name: 'Customer',
  components: {
    CustomerForm
  },
  mounted(){
    this.loadCustomer();
  },
  methods:{
    async updateCustomer(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const customerId = this.$route.params.id;
        const customer = await Customer.updateCustomer(this.apiUrl, customerId, form, loginInfo);
        this.$router.push('/customers');
      }catch(err){
        console.log(err);
      }
    },
    async loadCustomer(){
      try{
        const loginInfo = Common.getLoginInfo();
        const customerId = this.$route.params.id;
        const customer = await Customer.loadCustomer(this.apiUrl, customerId, loginInfo);
        this.setCurrentCustomer(customer);
      }catch(err){
        console.log(err);
      }
    },
    ...mapActions(['setCurrentCustomer']),
  },
  computed: mapState({
   currentCustomer: state => state.customer.currentCustomer,
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
